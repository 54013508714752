.form_container.flex_container.column.raise > input {
  text-align: left;
  padding: 10px 15px;
  font-size: medium;
  width: 100%;
}
.text_area {
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid #c5c3c3;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
  margin-top: 10px;
}
.raiseSelect {
  background-color: transparent;
}
