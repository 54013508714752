.firstDash {
  padding: 30px 0px;
  margin: auto;
  margin-top: 135px;
  margin-bottom: 20px;
  display: block;
  border-radius: 25px;
  width: 65%;
  background-color: #e2e2e282;
}
.heading {
  text-align: center;
  margin: 25px 0;
}

.name {
  color: #57307d;
  font-weight: 700;
}

.heading > h1 {
  margin-bottom: 10px;
}
.heading > h2 {
  color: #232323;
}

.form_wrapper {
  margin: auto;
  display: block;
  width: 60%;
}

.dashform {
  margin: 8px 5px;
}

.form_wrapper > h2 {
  text-align: center;
  margin-bottom: 38px;
}
.form_checkBox > h2 {
  text-align: center;
  margin: 36px 0 20px 0;
}
.form_container {
  margin: auto;
}
.op {
  display: block;
  margin: auto;
  text-align: center;
  text-transform: capitalize;
  line-height: 30px;
}
.next {
  margin: auto;
  display: block;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 13px 50px;
  font-size: 14px;
  background: #57307d;
  border-radius: 5px;
  color: #fff;
  transition: all 0.3s ease-in;
}

.next:hover {
  background-color: transparent;
  font-weight: 700;
  border: 2px solid #57307d;
  color: #57307d;
}

@media screen and (max-width: 500px) {
  .firstDash {
    width: 90% !important;
  }
  .form_container.flex_container.column > input {
    width: 90% !important;
  }
}
