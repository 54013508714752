.totalExp {
  display: block;
  margin: auto;
  text-align: center;
  background: #fff;
  padding: 25px 0;
  margin-bottom: 20px;
  border-radius: 0px 0px 10px 10px;
  font-size: 12px;
  box-shadow: 0px 5px 15px -10px rgb(0 0 0 / 75%);
}
.textExpense {
  position: relative;
  display: inline;
}
.textExpense::before {
  width: 40px;
  height: 8px;
  /* background-color: red; */
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  content: "";
  position: absolute;
  left: -50px;
  border-radius: 12px;
  top: 6px;
}
