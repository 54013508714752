.secondDashForm {
  margin-top: 150px;
}
.statusField {
  text-align: left !important;
}
.addData {
  margin: 0px auto 15px auto;
}
.addIcon {
  display: flex;
  align-items: center;
  margin: 5px 11px -7px;
  color: #9b9797;
}

.mobileField {
  width: 100% !important;
  padding: 10px 0 !important;
}

#mui-2-label,
#mui-1-label {
  margin-left: 10px !important;
  margin-top: 5px !important;
}

/* .formSelect {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.icon_second,
.addIcon > p {
  cursor: pointer;
  margin: 0 10px;
}
.icon_second:hover,
.addIcon > p:hover {
  color: #232323;
}
