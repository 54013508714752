.footer {
  /* margin: 40px 70px; */
  /* padding: 30px; */
  display: flex;
  color: #ffc632;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #57307d;
  /* width: 50%; */
}
.footerSection {
  /* background: #4a515a; */
  background: #57307d;
}
.intro > h5 {
  max-width: 325px;
  text-align: left;
  margin-top: 0.5rem !important;
}
hr {
  border: 0.5px solid #e9e9e9;
  margin: 10px 45px;
}
.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffc632;
  padding: 9px 0 30px 0;
}

.intro > img {
  width: 208px;
  height: 103px;
  margin: 10px;
  image-rendering: pixelated;
  box-shadow: 0 0 5px 2px #070707;
  filter: hue-rotate(45deg);
}

.comp_name {
  margin-left: 15px;
}

.footer > div {
  margin: 22px 35px;
  text-align: -webkit-center;
  padding: 16px 15px;
  text-align: -moz-center;
}
.footer > div > h1 {
  text-transform: capitalize;
  width: max-content;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0.5rem;
  text-align: center;
}
/* .footer > div::after {
  border-right: 2px solid grey;
  position: absolute;
  content: "";
  width: 56px;
  right: -45px;
  height: 200px;
  top: 4px;
} */
.footer > div > p {
  text-transform: capitalize;
  margin: 20px 0;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  padding: 5px;
  border-top: 1px solid #8080804d;
  border-bottom: 1px solid #8080804d;
  font-weight: 800;
}

.footer > div > p:hover {
  box-shadow: 0px 6px 3px rgb(0 0 0 / 16%);
  cursor: pointer;
}

.link {
  color: #fff;
}

.quoteSection {
  padding: 50px 0;
  /* background-color: #a0cdec; */
  background-color: #d8d7d9;
  display: block;
}
.contain {
  max-width: 960px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.contain > h1 {
  color: #000000;
  font-size: 44px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
}
.contain > p {
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
  max-width: 83.333333%;
  text-align: center;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 10px;
}
.freeTrial__btn {
  border-radius: 23px;
  background-color: #ffc632;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  padding: 10px 25px;
  min-height: 45px;
  border: none;
  white-space: unset;
  cursor: pointer;
  text-decoration: none !important;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 50px;
  display: block;
}
.footer__points {
  margin: 10px 0;
  color: white;
}

@media screen and (max-width: 400px) {
  .footer {
    margin: 40px 100px;
    width: 50%;
  }
  .footer > div {
    margin: 0px 22px;
    text-align: -webkit-center;
    padding: 48px 5px 34px;
    text-align: -moz-center;
  }
  .footerSection {
    margin-top: -40px;
  }
}
