* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-weight: normal;
  text-decoration: none;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* html {
  overflow: auto;
} */
ol,
ul {
  list-style: none;
}
del {
  text-decoration: line-through;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: #1c2c40;
  overflow-x: hidden;
  min-width: 320px;
  font-weight: 400;
  /* background-color: #fec802; */
  background-color: #fff;
}
h3 {
  color: crimson;
}
input,
textarea,
select {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  /* width: 100%; */
}

a {
  color: #1c2c40;
}
a:hover,
.submit:hover {
  filter: alpha(opacity = 85);
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}
.thumb {
  display: block;
}
.thumb img {
  display: block;
  width: 100%;
}
p {
  line-height: 1.6em;
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
}
.hidden {
  display: none;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 980px;
}

header {
  background-color: #1c2c40;
  color: rgb(60, 214, 235);
  text-align: center;
  padding: 30px;
}
header h3 {
  font-size: 50px;
  font-weight: 900;
}
#slider-container {
  margin-bottom: 30px;
  /* width: 175%; */
}
#slider-container div.container {
  padding: 36px 19px;
  text-align: center;
}
#slider-container div.container img {
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 10px;
}
#slider-container div.container h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 25px !important;
  background-color: #fff;
  padding: 8px 11px !important;
  width: fit-content !important;
  margin: 0 auto;
  border-radius: 8px !important;
}
#slider-container div.container p {
  font-style: italic;
}
@media screen and (max-width: 500px) {
  #slider-container {
    margin-bottom: 30px;
    width: 100%;
  }
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-8.MuiGrid-grid-md-5.MuiGrid-grid-lg-4.MuiGrid-grid-xl-4.makeStyles-signupform-6.css-ytc5qc-MuiGrid-root {
    width: 100%;
    height: auto;
  }
}
