.box__form {
  margin-top: 170px !important;
}
.pass_forgot {
  margin: 15px 0;
}

.activation_box {
  /* margin-bottom: -50px !important; */
  padding: 50px 20px;
  text-align: center;
}

.error {
  border: 2px solid rgb(148, 14, 14) !important;
}

.activation_box > h4 {
  font-size: 27px;
}

.back {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  background-color: transparent;
}
.MuiPaper-rounded {
  position: relative;
}
main.MuiContainer-root.MuiContainer-maxWidthXs {
  margin: 150px auto 40px auto;
  text-transform: capitalize;
  padding: 0px 11px;
}
.MuiPaper-root.makeStyles-paper-3.MuiPaper-elevation10.MuiPaper-rounded {
  border-radius: 15px;
  padding: 39px 30px;
  text-align: center;
}
.btn__next {
  margin: 30px 0 0 0 !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}
.MuiAvatar-colorDefault {
  background-color: #3f51b5 !important;

  margin: 0px 0 18px 0 !important;
}
.css-bsqp9w-MuiSvgIcon-root {
  color: white !important;
}
