@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.table {
  background: #fff;
  padding: 30px 30px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 13px 20px;
  width: 100%;
  border-radius: 15px;
}

body {
  -webkit-tap-highlight-color: red !important;
  -webkit-tap-highlight-color: transparent !important;
}
::selection {
  color: #fff !important;
  background-color: blueviolet;
}

.css-kgmwx6-MuiPaper-root-MuiAlert-root {
  margin: 9px 5px 0 18px !important;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

/* .disabled:hover {
  border-radius: 10px !important;
} */

p.subsData {
  margin: 20px 48px -13px;
  font-weight: 700;
  color: #57307d;
  text-transform: capitalize;
}

.selectSubsCompany {
  margin: 5px 10px 40px !important;
}

::moz-selection {
  color: #fff !important;
  background-color: blueviolet;
}

.table .price-section {
  display: flex;
  justify-content: center;
}
.table .price-area {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 2px;
}

.aj_p {
  text-align: center;
}

p.aj_des {
  color: grey;
  padding-bottom: 30px;
  font-size: 14px;
}

/* .table.premium {
  margin: -20px;
} */

.premium_all {
  padding-top: 22px;
}

.price-area .inner-area {
  height: 100%;
  width: 100%;
  line-height: 117px;
  text-align: center;
  color: #fff;
  position: relative;
}

.price-area .inner-area .text {
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  top: -10px;
  left: 5px;
}

.price-area .inner-area .price {
  font-size: 45px;
  font-weight: 500;
  margin-left: 16px;
}

.table .package-name {
  width: 100%;
  height: 2px;
  margin: 35px 0;
  position: relative;
}

.table .features li {
  margin-bottom: 15px;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.features li .list-name {
  font-size: 17px;
  font-weight: 400;
}

.features li .icon {
  font-size: 15px;
}

.features li .icon.check {
  color: #2db94d;
}

.features li .icon.cross {
  color: #cd3241;
}

.table .btns {
  width: 100%;
  display: flex;
  justify-content: center;
}

.table .btns button {
  width: 80%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.table .btns button:hover {
  border-radius: 5px;
}

.basic ::selection,
.basic .price-area,
.basic .inner-area,
.basic .head_tab h2 {
  color: #2567b3;
}

.basic .btns button {
  background: #2567b3;
  color: #fff;
  margin-top: -75px;
}

.basic .btns button:hover {
  background: rgb(212 227 249 / 50%);
  color: #2567b3;
  border: 2px solid #2567b3;
}

.premium ::selection,
.premium .price-area,
.premium .inner-area,
.premium .head_tab h2 {
  color: #57307d;
}

.premium .btns button {
  background: #57307d;
  color: #fff;
  margin-top: -75px;
}

.premium .btns button:hover {
  background: #f2ecf987;
  color: #57307d;
  border: 2px solid #57307d;
}

.mo {
  font-size: 12px;
}

@media (max-width: 756px) {
  .wrapper .table {
    width: 100%;
  }

  .table.premium {
    margin: 40px 0px;
  }
}

::selection {
  color: #fff;
}

.table .ribbon {
  width: 150px;
  height: 141px;
  position: absolute;
  top: 0px;
  left: -1px;
  overflow: hidden;
}

.table .ribbon::before,
table .ribbon::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #ba24f0;
  border-top-color: transparent;
  border-left-color: transparent;
}

.table .ribbon::before {
  top: 0px;
  right: 15px;
}

.table .ribbon::after {
  bottom: 15px;
  left: 0px;
}

.table .ribbon span {
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #57307d;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}
@media (max-width: 500px) {
  .head_tab {
    margin-top: 60px;
  }
  .price-area .inner-area {
    margin-left: -17px;
  }
  .table .btns button {
    width: 100%;
    font-size: 16px;
    padding: 6px;
  }
}
