.topbar {
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1100000;
  left: 0;
}

.topLeft {
  width: 100%;
}

.topbarWrapper {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
}

.topRight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  cursor: pointer !important;
  display: flex;
  justify-content: left !important;
  align-items: center !important;
}
.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}
