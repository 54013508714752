.others {
  flex: 4;
  /* background-color: blue; */
  padding-left: 280px;
}

.table_left_head {
  position: sticky;
  top: -2px;
  left: 0;
  opacity: 1;
  background: #fbe5ff;
}

.containers {
  height: 100vh;
}
.expenseCalc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cashInjection,
.balance {
  padding: 35px 40px 35px 20px !important;
  width: 100%;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  margin: 20px 14px 20px 20px;
  background-color: #fff;
  border-radius: 10px;
  font-size: initial;
}

.purple {
  color: #57307d;
}
.loadings {
  margin-left: 50px;
  margin-top: 50px;
}

.tableData {
  height: 160px;
  overflow-y: scroll;
  display: block;
  margin: auto;
}

.cashInjection {
  margin-left: 5px !important;
  text-align: center;
  padding: 5px 20px 23px 20px !important;
}

button#test-table-xls-button {
  padding: 10px;
  font-weight: 700;
  font-size: 12px;
  background: #57307d;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  align-items: center;
}

a.downloadCSV {
  color: #fff;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
}

.noShow {
  display: none;
}

h2.balances,
h4.balances,
.injection_head {
  line-height: 50px;
  width: auto;
  max-width: 364px;
  margin-left: 20px;
}
h2.balances {
  font-size: 20px;
}
.injection_head {
  max-width: 180px;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}
.addCash {
  color: blueviolet !important;
  border: 1.6px solid blueviolet !important;
  margin: 21px 10px !important;
  padding: 13px !important;
  text-transform: capitalize !important;
  width: 100%;
}
.injection_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
