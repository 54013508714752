.filter {
  padding: 15px 20px 30px 20px;
  /* box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%); */
  margin: 0px 10px;
  /* background-color: #fff; */
  border-radius: 10px;
  width: 70%;
}
.others {
  padding-left: 261px;
}

.filter_Container {
  margin: 25px 0px;
}

.filter_by {
  padding: 5px 20px 10px 20px;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  margin: 20px 17px;
  background-color: #fff;
  border-radius: 10px;
}
.filter_btn {
  padding: 30px 15px;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  margin: 20px;
  background-color: #fff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
}

.pie_chart {
  /* margin-left: 10px; */
  width: 100% !important;
}

.pieChartFig {
  /* padding: 5px 20px 10px 20px; */
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  /* margin: 20px 17px; */
  background-color: #fff;
  /* border-radius: 10px; */
  border-radius: 10px 10px 0px 10px;
}
.recharts-wrapper {
  width: 100% !important;
  height: 100% !important;
  /* height: 350px !important; */
}

/* .recharts-responsive-container.pieChartFig {
  width: 500px !important;
} */

.charts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line_chart {
  padding: 20px 13px 0px 13px;
  margin: 35px 10px;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  background-color: #fff;
  border-radius: 10px;
  width: 1000px;
  height: 310px;
}

svg.recharts-surface {
  /* width: 100%; */
  height: 100%;
  /* height: 350px; */
}

.sideIcon {
  margin-bottom: 10px;
  margin-right: 10px;
  display: none !important;
}

.none {
  display: none !important;
}

.block {
  display: inline !important;
}

.data_table {
  padding: 12px;
  margin: 0px 10px 20px;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  background-color: #fff;
  border-radius: 10px;
}

.companyFilter {
  margin: 10px 0;
}
.companyFilter > h2 {
  margin: 15px 18px;
}
select#selectBox1 {
  margin: 0 15px;
}
.filter_by {
  padding: 20px 35px;
}

.filter_by {
  padding: 30px 41px;
  width: 100%;
}
.others {
  background-color: #f9fafc;
}

@media screen and (max-width: 1240px) {
  .charts {
    flex-direction: column;
  }
  .line_chart {
    /* overflow:   auto; */
    width: 100%;
  }
  .recharts-responsive-container {
    width: 100% !important;
  }
  h2.balances,
  h4.balances,
  .injection_head {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1190px) {
  .profile_card {
    flex-direction: column;
  }
  .userData {
    margin: 0 !important;
  }
  .userValue {
    margin: 0px 16px !important;
  }
  .bgFlag {
    width: 100% !important;
    left: 0 !important;
  }
  h2.balances,
  h4.balances,
  .injection_head {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1000px) {
  .filter_btn {
    width: auto !important;
  }
  .sideIcon {
    display: inline !important;
    color: grey;
    cursor: pointer;
    transition: all 0.5s ease-in;
  }
  .deskTopView {
    display: none;
  }

  .sideIcon:hover {
    color: #232323;
  }

  .cross_btn:hover {
    color: red;
  }

  .sidebar {
    display: none;
  }
  .others {
    padding-left: 0px;
  }
  .mobileView {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
  }
}
@media screen and (max-width: 850px) {
  .pie_chart {
    flex-direction: column;
  }
  .balance {
    text-align: center;
    text-align: -webkit-center;
  }
  .expenseCalc {
    flex-direction: column;
  }
  .filter_Container {
    flex-direction: column;
  }
  .display_wrap {
    flex-direction: column;
  }
  .container_myCompanies {
    width: 97% !important;
    margin: 17px 12px !important;
  }
  .rdrStaticRanges {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap !important;
    flex-direction: row;
    justify-content: center;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .rdrDateRangePickerWrapper {
    flex-direction: column-reverse !important;
    width: 100%;
    margin: 0 20px !important;
  }
  .rdrMonth {
    width: 100% !important;
  }
  h2.balances,
  h4.balances,
  .injection_head {
    font-size: 18px !important;
  }
  h2.balances {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 500px) {
  .company_wrapper,
  .filter_wrapper {
    flex-direction: column;
    gap: 10px;
  }
  select#selectBox1 {
    margin: 7px 15px;
  }
  .range_btn {
    width: 100%;
  }
  .header {
    flex-direction: column !important;
  }
  /* .recharts-responsive-container.pieChartFig {
    width: 385px !important;
    height: 370px !important;
  } */
}
@media screen and (max-width: 350px) {
  .filter_btn {
    flex-direction: column;
  }
  button.reset,
  button.apply_submit {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  h2.balances,
  h4.balances,
  .injection_head {
    font-size: 13px !important;
  }
}
