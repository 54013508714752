.customer__form {
  padding: 15px;
}
.dropDown {
  width: 100%;
  font-size: 12px;
  padding: 18.5px 14px;
  border: 1px solid #cac2c2;
  border-radius: 5px;
  background: transparent !important;
  margin: 5px 0;
}
select:required:invalid {
  color: gray;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnz {
  margin: 10px !important;
}
