.form_container.flex_container.column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container.flex_container.column > input {
  margin: 15px 0;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  font-weight: 700;
  border: 1px solid #c5c3c3;
  background-color: transparent;
  border-radius: 5px;
  font-size: 18px;
}
.form_container.flex_container.column > label {
  color: #57307d;
  font-weight: 700;
  text-transform: capitalize;
}
