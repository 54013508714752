.company_wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}
.icon_select {
  display: flex;
  align-items: center;
  justify-content: start;
}
.icon_select > p {
  margin: 0 5px;
  font-size: small;
  font-weight: 700;
}

#loadBalance {
  display: block;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.topSelects:focus {
  width: 100%;
}

.get_data {
  padding: 12px 0px;
  cursor: pointer;
  margin: 0 10px;

  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background-color: transparent;
  border: 2px solid #57307d;
  color: #57307d;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.get_data:hover {
  background-color: #f2f2f2;
}

.filter_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}
.filter_wrapper_selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.date_range {
  position: absolute;
  top: 80px;
  left: -11px;
  z-index: 11000;
}

.filter_by {
  padding: 35px 40px 35px 20px !important;
}

.filter_by > h2 {
  margin-left: 20px;
  margin-bottom: 10px;
}

.range_btn {
  padding: 16px 33px;
  margin: 0 13px;
  border-radius: 5px;
  color: #938e8e;
  transition: background-color 0.3s ease-in-out;
  outline: none;
  border: 1px solid #cac2c2;
  background-color: transparent;
  cursor: pointer;
}

button.reset,
button.apply_submit {
  padding: 21px 33px;
  cursor: pointer;
  margin: 0 13px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  /* background-color: rgb(251, 251, 255); */
  background-color: #82ca9d69;
  transition: background-color 0.3s ease-in-out;
}

button.apply_submit {
  padding: 22px 19px;
}

button.reset {
  margin: 0 30px;
}

.range_btn:hover {
  color: #232323;
}

button.apply_submit:hover,
.reset:hover {
  background-color: #f2f2f2;
  border: 2px solid #82ca9d69;
  color: #1f5c36f1;
}

.company_wrapper {
  position: relative;
}
.filter_Container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.range_btn {
  width: 100%;
}
select.dropDown.dashform {
  width: 100%;
}
