.homepage {
  margin-top: 80px;
  padding: 40px;
  background-image: url("https://resources.tallysolutions.com/wp-content/uploads/2020/tallyprime/prime-banner.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom center;
  /* background-color: transparent !important; */
  background-color: #ecebebbf;
  justify-content: center;
  align-items: center;
}

.addCompanyBtns {
  background-color: transparent !important;
  color: #232323 !important;
  font-weight: 700 !important;
  /* width: 100% !important; */
  /* width: 350px !important; */
  width: 100% !important;
  line-height: 35px !important;
  /* margin-bottom: 10px !important; */
  /* box-shadow: none !important; */
  color: #32154e !important;
  background: #44ffff78 !important;
}

.firstBtnDash {
  width: 100% !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 700 !important;
  padding: 11px 3px !important;
}

.google {
  width: 100% !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin: 15px 0;
}

.form__home {
  width: 100%;
}

.formDiv {
  padding: 20px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  position: relative !important;
  background-color: #eceaff8f !important;
  border-radius: 12px !important;
  background-color: #eceaff8f !important;
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%),
    0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%) !important;
}

.heading_homepage {
  margin: 20px 0 30px !important;
  text-align: center;
  padding: 0 37px;
  width: 1000px;
  margin-bottom: 30px;
  line-height: 2.235 !important;
}

.signInForm {
  justify-content: center;
}

h4.MuiTypography-root.MuiTypography-h4.css-5lbw0b-MuiTypography-root {
  margin: 20px 0;
  text-align: center;
  padding: 0 37px;
  width: 1000px;
  margin-bottom: 30px;
  line-height: 1.235 !important;
}
.cardData {
  margin: 110px 0 50px 0;
}
.card__flexing {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}

.makeStyles-form-24 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px !important;
}

.makeStyles-form-7 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 13px;
}
.field__formHeader {
  width: 1000px !important;
  max-width: 500px !important;
  margin-bottom: 3px !important;
}
.MuiGrid-root.MuiGrid-container.homepage.css-11lq3yg-MuiGrid-root {
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.video {
  border-radius: 16px;
  /* box-shadow: 2px 18px 24px rgb(0 0 0 / 38%); */
  cursor: pointer;
}
.video:hover {
  box-shadow: 2px 18px 24px rgb(0 0 0 / 38%);
}
.video::before {
  content: "";
  width: 187px;
  height: 92px;
  position: absolute;
  bottom: 40px;
  right: 15px;
  background: url(https://www.sageintacct.com/sites/all/themes/sageintacct/assets/img/homepage/dots.svg);
  z-index: 0;
}
.css-1sra7t5-MuiTypography-root {
  padding: 36px 70px;
}
.slick-list {
  margin: 6px;
  padding: 22px;
}
#slider-container div.container {
  padding: 32px 3px;
  text-align: center;
}
#slider-container div.container h6 {
  padding: 12px 0 !important;
  width: 45% !important;
  margin: 0 auto;
  border-radius: 12px !important;
}
#slider-container div.container img {
  margin-bottom: 20px;
  border-bottom: 7px solid #8080808f !important;
  border-radius: 100% !important;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12.css-1idn90j-MuiGrid-root {
  padding: 5px;
}
#slider-container div.container p {
  margin-top: 10px;
}
#slider-container div.container {
  cursor: pointer;
  transition: all 0.3s ease-in !important;
}
#slider-container div.container:hover {
  display: block !important;
  transform: scale(1.08);
}
.css-15s4tt4-MuiPaper-root {
  background-color: #eceaff8f !important;
  border-radius: 12px !important;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #fff;
  border-radius: 5px;
  padding: 0 5px;
}
.makeStyles-button-8 {
  margin: 8px 0 !important;
  padding: 8px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButtonBase-root.makeStyles-button-8.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
  padding: 11px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-7.MuiGrid-grid-lg-8.MuiGrid-grid-xl-8.css-30iazq-MuiGrid-root {
  margin: 20px 0 !important;
}
#slider-container div.container,
.slick-list {
  background-color: #ffffff;
  margin: 16px 0 !important;
}
#slider-container div.container {
  margin: 0 0px !important;
  background-color: transparent;
}
.react-player.fixed-bottom {
  /* width: 560px; */
  width: 90% !important;
  height: 100% !important;
  margin: auto !important;
}
video.md-opjjpmhoiojifppkkcdabiobhakljdgm,
video {
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  /* border-bottom: 7px solid #3332328f !important; */
  border-left: 7px solid #070707f8 !important;
  border-right: 7px solid #070707f8 !important;
  border-style: 7px solid #070707f8 !important;
}
video.md-opjjpmhoiojifppkkcdabiobhakljdgm:hover,
video:hover {
  display: block !important;
  transform: scale(1.02);
  box-shadow: 2px 18px 24px rgb(0 0 0 / 38%);
}

.google {
  margin: 16px 0 !important;
  padding: 8px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  width: 350px;
}
@media screen and (max-width: 930px) {
  .card__flexing {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
  .formDiv {
    padding: 12px;
  }
  .homepage {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 600px) {
  .headerDashboard {
    font-size: 25px !important;
    padding: 0px !important;
  }
}
@media screen and (max-width: 700px) {
  .flex {
    flex-direction: column-reverse;
  }
}
