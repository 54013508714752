.logo__navbar {
  cursor: pointer;
  width: 80px;
  height: 68px;
}

p.tel.offer_data {
  color: yellow;
  font-size: 13px;
  font-weight: 700;
}

.loading_login {
  margin: 67px auto -67px auto !important;
  display: block !important;
}

.header {
  padding: 9px 200px 9px 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #57307d;
  top: 0;
  left: 0;
  /* height: 16px; */
  color: white;
  margin-bottom: 30px;
  z-index: 999;
  position: fixed;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 21%);
}

button.menuSignIn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 7px 17px 7px 0;
  /* width: 100%; */
  width: max-content !important;
  font-size: 14px;
  background: #57307d;
  border-radius: 5px;
  color: #fff;
}

.mobile_header {
  display: none !important;
  position: static !important;
  margin-bottom: 0px !important;
  box-shadow: none !important;
  border-radius: 10px;
}

.navbar__css {
  padding: 5px 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffffe3;
  top: 36px;
  left: 0;
  margin-bottom: 30px;
  z-index: 999;
  height: 70px;
  position: fixed;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 21%);
  margin: 9px 0;
}
.menu__option {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 70%;
}

.menuOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.mobile {
  display: none;
}
.btn {
  margin: 0 10px !important;
  color: #57307d !important;
  font-weight: bold !important;
}
.visible {
  display: block !important;
}
.menuIcon {
  cursor: pointer;
  display: none;
}
.signIn__btn {
  display: block !important;
  width: max-content;
  border: none !important;
  outline: none !important;
  background-color: #57307d !important;
  color: #fff !important;
  font-size: 16px;
  cursor: pointer;
}

.dataProfile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconValue {
  margin: 0 10px;
}

@media screen and (max-width: 1200px) {
  .navbar__css {
    top: 36px !important;
    padding: 0px 58px;
    justify-content: space-between;
  }
  .header {
    padding: 10px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background-color: #2567b3; */
    top: 0;
    left: 0;
    /* height: 16px; */
    color: white;
    margin-bottom: 10px !important;
    z-index: 999;
    position: fixed;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 21%);
  }
}

@media screen and (max-width: 880px) {
  .navbar__css {
    top: 2px !important;
    padding: 0px 58px;
    justify-content: space-between;
    height: 89px !important;
  }

  /* .topbarWrapper {
    flex-direction: column-reverse;
  } */
  .logo {
    justify-content: center !important;
    align-items: center !important;
  }
  .filter {
    width: 100% !important;
  }
  .mobile_logo {
    width: 55px;
  }

  button.menuSignIn {
    /* padding: 7px 50px 7px 50px !important; */
    width: 100% !important;
  }

  .mobile_header {
    display: flex !important;
  }

  .desktop {
    display: none !important;
  }

  .menu__option {
    display: none;
  }
  .menuIcon {
    display: block;
  }
  .menu__option {
    top: 0;
    left: 0;
    display: block;
    width: 95% !important;
    /* height: 52% !important; */
    height: 370px !important;
    position: fixed;
    background-color: #fff;
    padding: 10px;
    transition: transform 0.5s linear;
    z-index: 1000;
    transform: translate3d(0, -105%, 0);
    margin-left: 30px !important;
  }

  .animation {
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 10px 15px rgb(0 0 0 / 50%);
  }

  .btn {
    display: block !important;
    margin: 27px auto !important;
    /* color: #d8d7d9 !important; */
    text-transform: capitalize !important;
    font-size: 17px !important;
    font-weight: bold !important;
  }
  .menuOptions {
    display: block !important;
    margin-top: -21px;
  }
  .signIn__btn {
    /* margin: auto !important; */
    /* background-color: #2567b3 !important; */
    font-weight: bold !important;
  }
  .mobile {
    display: flex !important;
    justify-content: space-between;
    padding: 10px 30px;
  }
  .cross {
    cursor: pointer;
  }
  p.tel.offer_data {
    color: yellow;
    font-weight: 700;
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .navbar__css {
    top: -10px !important;
    padding: 0px 20px;
    justify-content: space-between;
  }
  .tel {
    font-size: 12px !important;
  }
  .cardDesc {
    font-size: 16px !important;
  }
  .header {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
    margin-bottom: 30px;
    z-index: 999;
    position: fixed;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 21%);
  }
  .menu__option {
    display: none;
  }
  .menuIcon {
    display: block;
  }
  .menu__option {
    top: 0;
    left: 0;
    display: block;
    width: 95% !important;
    height: 360px !important;
    position: fixed;
    background-color: #fff;
    padding: 10px;
    transition: transform 0.5s linear;
    z-index: 1000;
    transform: translate3d(0, -100%, 0);
    margin-left: 13px !important;
    /* height: 420px !important; */
  }

  .animation {
    transform: translate3d(0, 0, 0);
    /* overflow: hidden;
    overflow-y: auto; */
    box-shadow: 0px 10px 15px rgb(0 0 0 / 50%);
  }

  .btn {
    display: block !important;
    margin: 27px auto !important;
    /* color: #d8d7d9 !important; */
    text-transform: capitalize !important;
    font-size: 17px !important;
    font-weight: bold !important;
  }
  .menuOptions {
    display: block !important;
    margin-top: -21px;
  }
  .signIn__btn {
    /* margin: auto !important; */
    /* background-color: #2567b3 !important; */
    font-weight: bold !important;
  }
  .mobile {
    display: flex !important;
    justify-content: space-between;
    padding: 10px 30px;
  }
  .cross {
    cursor: pointer;
  }
}
@media screen and (max-width: 400px) {
  .menu__option {
    height: 370px !important;
  }
}
