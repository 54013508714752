.myCompany {
  margin: 15px 0;
  display: block;
}
.container_myCompany {
  background-color: #ffffff;
  padding: 0px 15px;
  margin: 0 30px;
  border-radius: 10px;
  box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px,
    rgb(159 162 191 / 32%) 0px 2px 2px;
}

select#selectBox2 {
  border: none;
}

span.headings {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border: none !important;
  /* margin: 15px 20px; */
}

.dataHead {
  justify-content: center;
}

.members_details > p {
  padding: 15px 10px;
  text-align: center;
  background: rgba(34, 51, 84, 0.02);
  border-radius: 8px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  transition: background-color 0.5s ease 0s;
}

.members_details > p:hover {
  box-shadow: rgb(129 154 187 / 8%) 0px 6px 6px,
    rgb(129 139 168 / 12%) 0px 2px 0px;
}

input.input_member {
  padding: 8px;
  border: 1px solid #d4cccc;
  background: transparent;
  font-weight: 700;
  border-radius: 5px;
  font-size: 13px;
}

.outLineBtn {
  margin: 21px 10px !important;
  padding: 13px !important;
  text-transform: capitalize !important;
  width: 60% !important;
  background: #00376ee0 !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 10px;
}
.outLineBtn:hover {
  background-color: #fff !important;
  color: #00376ee0 !important;
  border: 2px solid #00376ee0 !important;
}

.warning {
  background: #be5906 !important;
  transition: background-color 0.2s ease;
}
.warning:hover {
  border: 1.6px solid #be5906 !important;
  color: #be5906 !important;
  background-color: #fff !important;
  border-radius: 10px !important;
}
.deletes {
  background: #760c05 !important;
}
.deletes:hover {
  border: 1.6px solid #760c05 !important;
  background: #fff !important;
  color: #760c05 !important;
  border-radius: 10px !important;
}
.member {
  background: #57307d !important;
}
.member:hover {
  border: 1.6px solid #57307d !important;
  background: #fff !important;
  color: #57307d !important;
  border-radius: 10px !important;
}

.details {
  margin: auto;
  margin-top: 12px;
  border-bottom: 1px solid #e4e0e0;
  padding: 8px;
  border-radius: 10px;
}

.center {
  text-align: center !important;
}

.container_myCompanies {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  width: 100%;
  margin: 45px 30px;
  box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px,
    rgb(159 162 191 / 32%) 0px 2px 2px;
  padding: 5px 15px;
}

.members_details {
  padding: 15px;
  margin: 18px 16px;
  height: 195px;
  overflow-y: scroll;
  border: 1px solid #ececec;
  border-radius: 10px;
}

.select_company {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.addCompany {
  background: transparent;
  box-shadow: none;
  margin: 18px -13px -31px;
  justify-content: end !important;
}

.addCompanyBtn {
  margin: 5px !important;
  width: 200px !important;
  /* width: 100%; */
}
