.container > img {
  height: auto;
  max-width: 150px;
}
button.slick-arrow.slick-next {
  display: none !important;
}
@media screen and (max-width: 500px) {
  .container > img {
    height: auto;
    max-width: 90px;
  }
}
