.data_table {
  flex: 4;
}
span.material-icons.MuiIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc,
span.material-icons.MuiIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionDesc {
  display: none;
}

span.material-icons.MuiIcon-root.MuiIcon-fontSizeSmall {
  display: none;
}
