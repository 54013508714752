.account {
  margin: 45px 15px;
}

.profile_card {
  box-sizing: border-box;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: space-around;
  width: 100%;
  margin-top: 24px;
  padding: 0 20px 20px;
}
.company {
  border: 2px solid #5048e5 !important;
  width: 60% !important;
}

.psswrd {
  /* border: 2px solid #feb700 !important; */
  width: 60% !important;
}
.profile_head {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  background: #606c88; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3f4c6b,
    #606c88
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3f4c6b,
    #606c88
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.company_head {
  padding: 20px 24px;
}

.bold {
  font-weight: 700;
  margin: 0 10px 0 0;
}

.companyDetail {
  text-align: center;
}
.detail > p,
.company_items {
  margin: 10px 15px;
  padding: 10px;
  /* border: 1px solid rgb(207, 207, 207); */
  border-radius: 10px;
}

.company_items::-webkit-scrollbar {
  width: 5px;
}

.company_items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(192, 192, 192);
  border-radius: 10px;
}

.company_items::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

.members_details::-webkit-scrollbar {
  width: 5px;
}

.members_details::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(192, 192, 192);
  border-radius: 10px;
}

.members_details::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}
.tableData::-webkit-scrollbar {
  width: 5px;
}

.tableAll > thead > tr > th {
  background-color: #9c27b047;
}
.tableAll > tbody > tr > td {
  background-color: #9c27b024;
}

.tableData::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(192, 192, 192);
  border-radius: 10px;
}

.tableData::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

.deleteCompany {
  padding: 0 45px;
  margin: 13px;
}

.heading_account {
  margin-left: 45px;
  font-weight: 700;
}

.del {
  color: rgb(255, 110, 110) !important;
  border: 2px solid rgb(255, 110, 110) !important;
  outline: none !important;
  background-color: transparent !important;
  padding: 10px !important;
  display: block !important;
  margin: auto !important;
  width: 50%;
  margin-top: 20px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  max-width: 100% !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
}
.detail {
  display: flex;
  align-items: center;
}
.company_items {
  overflow-y: auto;
  border: none;
  height: 120px;
  padding: 0px 10px;
}

.linkCheck {
  color: #5048e5;
  text-decoration: underline;
}

.companys > p {
  margin: 5px 0;
  padding: 4px 9px;
  /* background: #ebfbfb; */
  border-radius: 10px;
  background: #606c88;
  background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);
  background: linear-gradient(to right, #3f4c6b, #606c88);
  color: #fff;
}
.companys > p:hover {
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
    0px 1px 2px rgb(100 116 139 / 10%);
}

/* .detail {
  margin: auto;
} */

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

span.head {
  margin: 0;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: block;
  color: #fff;
}

.user > img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
  border-radius: 50%;
}

.company_head {
  font-weight: 700;
  display: block;
}

.user {
  width: 70px;
  height: 70px;
  line-height: 1;
  display: block;
  margin: auto;
  margin-bottom: 80px;
}
span.name_detail > p {
  display: block;
  width: 210px;
  margin-left: -65px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.375;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  margin-bottom: 0.95em;
  color: #fff;
  text-align: center;
}
.hr {
  border-style: solid;
  border-color: #e6e8f0;
  border-bottom-width: thin;
  margin: 0 0 !important;
}
.btn {
  /* border: none !important; */
  outline: none !important;
  background-color: transparent !important;
  padding: 10px !important;
  display: block !important;
  margin: auto !important;
  width: 100%;
  box-shadow: none !important;
  color: #5048e5 !important;
  text-transform: capitalize !important;
  min-width: 64px !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
}

.checkBtn {
  width: 100%;
  min-width: max-content !important;
}

.btn:hover {
  background-color: rgba(80, 72, 229, 0.04) !important;
}

/* .psswrd:hover {
  background-color: rgba(39, 39, 13, 0.2) !important;
} */

.psswrd {
  margin-top: 15px !important;
  /* color: #feb700 !important; */
  color: #fff !important;
}

.detail_user {
  padding-bottom: 10px;
  background-color: #fff;
}

.userData {
  background-color: transparent;
  display: block;
  position: relative;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: rgb(129 154 187 / 8%) 0px 6px 6px,
    rgb(129 139 168 / 12%) 0px 2px 0px;
  width: 100%;
  padding: 0px;
  margin: 15px;
  padding-top: 21px;
}

.userValue:hover,
.profile:hover {
  transform: scale(1.03);
  display: block;
}

.buttons_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 12px;
  flex-wrap: wrap;
  /* gap: 15px 0; */
}

.bgFlag {
  position: absolute;
  width: 100%;
  /* background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%); */
  /* background: #654ea3;
  background: -webkit-linear-gradient(to right, #eaafc8, #654ea3);
  background: linear-gradient(to right, #eaafc8, #654ea3);   */
  background: #606c88; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3f4c6b,
    #606c88
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3f4c6b,
    #606c88
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 207px;
  border-radius: 12px;
  top: 0;
  left: 14.8px;
}

.userValue {
  margin: 0px 25px;
  width: 100%;
  position: relative;
  height: fit-content;
  transition: all 0.2s ease-in;
  margin-top: 14px;
}

.profile {
  background-color: #ffffff;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: rgb(129 154 187 / 8%) 0px 6px 6px,
    rgb(129 139 168 / 12%) 0px 2px 0px;
  width: 100%;
  padding: 0px 0px 10px;
  margin: 15px;
  transition: all 0.2s ease-in;
}
