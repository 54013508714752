.card {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 35px 40px;
  transition: all 0.5s ease-in-out;
}
.card:hover {
  transform: scale(1.08);
}
.cardBody {
  flex: 1 1 auto;
}
.card,
.card-body {
  text-align: center;
  padding: 2rem;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 20px 0px rgb(201 201 201);
  -moz-box-shadow: 0px 0px 20px 0px rgba(201, 201, 201, 1);
  box-shadow: 0px 0px 20px 0px rgb(201 201 201);
}
.card .title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 20px;
}
.line {
  margin: 0 auto;
  border: 2px solid #304119;
  width: 20%;
  margin-bottom: 20px;
}
.cardDesc {
  text-align: center !important;
}
