.addMember {
  margin: 135px 0 20px 0;
  padding: 10px 10px;
}
.addMember > h1 {
  text-align: center;
  text-transform: capitalize;
  line-height: 70px;
  word-spacing: 3px;
  letter-spacing: 1px;
}
.table__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  overflow-x: auto;
}

.mg_top {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.noBorder {
  border: none !important;
  font-size: 14px !important;
}

.loading {
  margin: 250px auto 130px auto !important;
  display: block !important;
}
svg.MuiCircularProgress-svg.css-1idz92c-MuiCircularProgress-svg {
  color: #692175 !important;
}

table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
}

th,
td {
  border: 1px solid #ffffff;
  padding: 8px 20px;
  /* max-width: 80px; */
  width: 1000px;
  word-wrap: break-word;
}

th {
  background-color: #ababab73;
  padding: 14px 8px;
  font-weight: 600;
  font-size: 16px;
}

td {
  background-color: #e0e0e073;
}

.table__form > form {
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 17px;
}

.addData {
  margin: 20px auto 10px auto;
  display: flex;
  width: 100%;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #f5f5f547;
}
.addDropDown {
  background: #e0e0e073 !important;
  width: 100%;
  border: 1px solid #80808057;
  padding: 10px;
  margin: 13px 2px;
  text-align: center;
  border-radius: 5px;
}

.addData > input,
.addData > select {
  background: #e0e0e073;
  width: 100%;
  border: 1px solid #80808057;
  padding: 10px;
  margin: 13px 2px;
  text-align: center;
  border-radius: 5px;
}
.submit_btn {
  padding: 7px;
  margin: 5px 10px;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
}

.nav__btn__addmember {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon__btn {
  margin: 0 5px;
}

.heading__btn {
  padding: 17px 0px;
  margin: 20px 17px;
  width: 140px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.5s ease-in-out;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.heading__btn.add {
  /* background: #dc7fec; */
  background: rgb(117 201 250 / 52%);
}

button.heading__btn.add:hover,
button.heading__btn.home:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgb(0 0 0 / 50%);
}

button.heading__btn.add:hover {
  background-color: rgb(126 206 253 / 68%) !important;
}

button.heading__btn.home {
  background: #ffff85;
}

button.submit_btn.add__btn {
  background: #00ff004f;
  border-radius: 3px;
  padding: 12px 0px;
  margin: 0px 0px 0 5px;
  width: 500px;
}
button.submit_btn.save {
  /* background: #00ff004f; */
  border-radius: 3px;
  /* padding: 8px 18px; */
}
button.submit_btn.delete {
  /* background: #ff818196; */
  border-radius: 3px;
  /* padding: 7px 9px; */
}

button.heading__btn.home:hover {
  background-color: #ffff5a;
}

button.submit_btn.edit:hover,
button.submit_btn.delete:hover,
button.submit_btn.save:hover,
button.submit_btn.cancel:hover {
  background-color: #d9d9d9;
}

.add__btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.submit_btn.edit {
  /* background: #c4c484; */
  border-radius: 3px;
  /* padding: 7px 18px; */
}

button.submit_btn.add__btn:hover {
  background: #00ff0094;
}

.submit_btn {
  width: 42px;
  max-width: 300px;
}
.edit_data {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: inherit;
  background: transparent;
  border: 1px solid #80808057;
  border-radius: 5px;
  display: block;
}

@media screen and (max-width: 600px) {
  .addData {
    flex-direction: column;
  }
  .submit_btn {
    max-width: none;
  }
  button.submit_btn.add__btn {
    width: 100%;
  }
}
