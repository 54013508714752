.sidebar {
  flex: 1 1;
  background-color: rgb(251, 251, 255);
  position: fixed;
  z-index: 10000000;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100vh;
}

.deskTopView {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.cross_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}
.mobileView {
  text-align: end;
  display: none;
}
.leftLogo {
  width: 50%;
}

.remove {
  display: none !important;
}

.sidebarMenu {
  margin-bottom: 10px;
}
.sidebarList {
  list-style: none;
  padding: 3px;
  margin: 10px 0px 5px;
}
.sidebarTitle {
  font-size: 14px;
  color: #232323;
  font-weight: 700;
}
.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.active {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
