.companyUser {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: content-box;
  margin-top: 100px;
  margin-bottom: 80px;
}
.contentWrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.headingCompany {
  width: 100%;
  margin-right: 4rem;
}

.headingCompany > h2 {
  margin: 0 0 5px;
  font-size: 2.5rem;
}
.headingCompany > p {
  font-size: 1.2rem;
  letter-spacing: 0.02rem;
  margin-top: 20px;
  max-width: 309px;
}

.companyLogo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.img {
  text-align: center;
  padding: 20px;
  flex: 1 1 20%;
}
.img > img {
  max-height: 100%;
  width: 100%;
}
strong {
  font-weight: 600;
}
@media screen and (max-width: 760px) {
  .contentWrap {
    flex-direction: column;
  }
  .headingCompany > p {
    text-align: center;
    display: block;
    margin: 20px auto;
  }
  .headingCompany {
    margin-right: 0rem !important;
  }
}
